import React, { Fragment } from 'react';
import { Badge } from 'reactstrap';
import { isFinite, get, sum, identity } from 'lodash';
import gravatar from 'gravatar';
import {
  __,
  __domainUserRole,
  __recordStatus,
  __skuStocktakeMode,
  __recordMode,
  __category,
  __orderStatus,
  __userActive,
  __countryName,
  __storageStandardStatus,
  __unit,
  __yesNo,
  getLanguage
} from '@stktk/locales';
import {
  Measurement,
  OrderStatusEnum,
  RecordMeasureEnum,
  SkuStocktakeModeEnum,
  StorageStandardStatusEnum,
  OrderRecordStatusEnum,
  UnitEnum,
  UnitPropertyEnum,
  colors,
  getRecordMeasureMeasurement,
  getTotalRecordReportAmount,
  getRecordSkuNormalizedAmount,
  getRecordsUnitNormalizedMeasurement,
  getSkuSizeMeasurement,
  UserActiveEnum,
  getTotalRecordSkuNormalizedAmount,
  getTotalRecordSkuPortionPrice,
  getTotalRecordUnitNormalizedAmount,
  getTotalRecordsUnitNormalizedMeasurement,
  getReportSkuMeasurement
} from '@stktk/commons';
import { formatDate } from '@stktk/logic/utils/date.js';
import { getReportableAmount } from '$utils/report';
import columnAggregators, { DefaultAggregated, UNIQUE } from '$ui/table/columnAggregators';
import columnFilters from '$ui/table/columnFilters';
import columnSorters from '$ui/table/columnSorters';
import TableDraggableCell from '$ui/table/TableDraggableCell';
import { Icon } from '$ui';
import TableEdit from '$ui/table/TableEdit.jsx';
import { RecordsValue, RecordsSaleValue, SkuImage } from '$loadable/ui.jsx';
import RecordsetStatistics from '$ui/RecordsetStatistics';
import { numberComparator } from '$utils/table.js';

export const DEFAULT_PRECISION = 2;

const round = (num, precision) => {
  // console.log(num, precision, Number(Math.round(num + 'e+' + precision) + 'e-' + precision));
  return Number(Math.round(num + 'e+' + precision) + 'e-' + precision);
};

// const NUMERIC_DISPLAY = number => isFinite(number) ? parseFloat((number).toFixed(precision)) : null;
export const numericDisplay = precision => value => value != null && isFinite(value) ? parseFloat((value).toFixed(precision)) : null;

const totalArrivedAmountIconProps = {
  [-1]: {
    variant: 'danger',
    icon: 'long-arrow-down'
  },
  [0]: {
    variant: 'success',
    icon: 'check'
  },
  [1]: {
    variant: 'warning',
    icon: 'long-arrow-up'
  }
};

// export const getNestedValue = (item, nestedPath) =>
//   nestedPath ? get(item, nestedPath) : item;

export const columnDefaultProps = {
  disableGroupBy: true,
  isCellEditable: () => true,
  expand: false,
  isHideable: true
};

export const createColumnProps = (defaultProps, columnProps = {}) => {
  const mergeProps = { ...defaultProps, ...columnProps };
  let props = {};
  if (mergeProps.isEditable) {
    props.Edited = TableEdit;
    if (typeof mergeProps.accessor === 'string')
      props.formElementProps = {
        ...columnDefaultProps.formElementProps,
        name: mergeProps.accessor,
        ...mergeProps.formElementProps || {}
      };
  }
  return { ...columnDefaultProps, ...mergeProps, ...props };
};

//FIXME: remove when columnConfig.amountWithUnit merged
export const createWeightColumnProps = ({ property, precision = DEFAULT_PRECISION, recordMeasure, ...props } = {}) => {
  const display = measurement => measurement.localeDisplay({ precision });
  return { ...columnDefaultProps, exporter: ({ cell /*, row*/ }) => display(cell.value),
    accessor: row => getRecordMeasureMeasurement(row, row.sku, recordMeasure) || new Measurement(),
    Cell: ({ cell: { value: measurement }}) => display(measurement),
    ...columnAggregators.dummy({ dummy: new Measurement(), display }),
    ...columnSorters.measurement({ property }),
    ...columnFilters.text({ property, display }), ...props };
};

export default {

  // COMMON COLUMNS

  text: ({ property, accessor = property, ...props }) => {
    return createColumnProps({
      id: property,
      accessor,
      formElement: 'input',
      formElementProps: { name: property, type: 'text' },
      ...columnAggregators.none(),
      ...columnSorters.text({ property }),
      ...columnFilters.text({ property })
    }, props);
  },

  number: ({ property, accessor = property, precision = DEFAULT_PRECISION, ...props }) => {
    const display = numericDisplay(precision);
    return createColumnProps({
      id: property,
      accessor,
      formElement: 'input',
      formElementProps: { name: property, type: 'number' },
      exporter: ({ cell: { value: number }/*, row*/ }) => display(number),
      Cell: ({ cell: { value }}) => display(value),
      ...columnAggregators.listUniq({ display, info: null }),
      ...columnSorters.number({ property }),
      ...columnFilters.number({ property, display })
    }, props);
  },

  email: ({ property, ...props }) => {
    return createColumnProps({
      Header: __('general_email'),
      id: property,
      accessor: property,
      formElement: 'input',
      formElementProps: { name: property, type: 'email' },
      ...columnAggregators.none(),
      ...columnSorters.text({ property }),
      ...columnFilters.text({ property })
    }, props);
  },

  country: ({ property, ...props }) => {
    const display = country => __countryName(country);
    return createColumnProps({
      Header: __('general_country'),
      id: property,
      accessor: property,
      Cell: ({ cell: { value }}) => display(value),
      formElement: 'country',
      formElementProps: { name: property },
      ...columnAggregators.none(),
      ...columnSorters.text({ property, display }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  gravatar: props => createColumnProps({
    Header: '',
    id: 'gravatar',
    accessor: 'email',
    width: 80,
    Cell: ({ cell: { value }, row: { original }}) =>
      original._temp ?
        (
          <span className="MembersRow-tempicon">
            <Icon icon="sync-alt" animation="spin" variant="muted" />
          </span>
        ) : (
          <img
            src={gravatar.url(value, { s: 26, d: 'mm' })}
          />
        ),
    ...columnAggregators.none(),
    ...columnSorters.none(),
    ...columnFilters.none()
  }, props),

  phone: ({ property, ...props }) => {
    return createColumnProps({
      Header: __('general_phone'),
      id: property,
      accessor: property,
      formElement: 'phone',
      ...columnAggregators.none(),
      ...columnSorters.text({ property }),
      ...columnFilters.text({ property })
    }, props);
  },

  name: ({ property, ...props }) => {
    return createColumnProps({
      Header: __('general_name'),
      id: property,
      accessor: property,
      formElement: 'input',
      formElementProps: { name: property, type: 'text' },
      ...columnAggregators.none(),
      ...columnSorters.text({ property }),
      ...columnFilters.text({ property })
    }, props);
  },

  memberStatus: props => createColumnProps({
    Header: __('general_status'),
    id: 'memberStatus',
    accessor: 'active',
    Cell: ({ cell: { value }}) => value == UserActiveEnum.ACTIVE ?
      <span className='text-success'>{__userActive(value)}</span>
      :
      <span className='text-muted'>{__userActive(value)}</span>
  }, props),

  orderStatus: ({ property = 'status', ...props }) => {
    const display = status => __orderStatus(status);
    return createColumnProps({
      Header: __('general_status'),
      id: property,
      accessor: property,
      Cell: ({ cell: { value }}) => display(value),
      ...columnAggregators.none(),
      ...columnSorters.number({ property }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  rgbColor: ({ property, ...props }) => {
    const display = rgbColor => rgbColor ? colors.name(rgbColor)[1] : __('app_category_color_unassigned');
    return createColumnProps({
      Header: __('general_color'),
      id: property,
      accessor: property,
      formElement: 'color',
      Cell: ({ cell: { value }}) => value ?
        <span>
          <Icon icon="circle" className="fas" style={{ color: value }} />
          {' '}
          {display(value)}
        </span> : <span className='text-muted'>{__('app_category_color_unassigned')}</span>,
      ...columnAggregators.none(),
      ...columnSorters.text({ property, display }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  comment: props => {
    const property = 'comment';
    return createColumnProps({
      Header: __('general_comment'),
      id: property,
      accessor: property,
      ...columnAggregators.none(),
      ...columnSorters.text({ property }),
      ...columnFilters.text({ property })
    }, props);
  },

  date: ({ property, accessor = property, dateFormat = 'L LT', language = getLanguage(), aggregateLast, ...props } = {}) => {
    const display = date => formatDate(date, language, dateFormat, '');
    return createColumnProps({
      id: property,
      tooltip: true,
      //tooltipAdditionalInfo: ,
      formElement: 'date',
      formElementProps: {
        name: property,
        format: dateFormat,
        saveButton: true,
        submitOnBlur: true,
        showTimeInput: true
      },
      accessor,
      Cell: ({ cell: { value }}) => display(value),
      ...(aggregateLast ? columnAggregators.maxDate({ property, display }) : columnAggregators.none()),
      ...columnSorters.date({ display, property }),
      ...columnFilters.text({ display, property })
    }, props);
  },

  userRole: props => createColumnProps({
    Header: __('general_role'),
    id: 'role',
    accessor: row => __domainUserRole(row.domainRole)
  }, props),

  creator: props => {
    const property = 'creator';
    const display = user => `${get(user, 'firstName', '')} ${get(user, 'lastName', '')}`;
    return createColumnProps({
      Header: __('general_creator'),
      id: property,
      exporter: ({ cell /*, row*/ }) => display(cell.value),
      accessor: 'creator',
      Cell: ({ row: { values }}) => display(values[property]),
      ...columnAggregators.listUniq({ unique: UNIQUE, display, info: null }),
      ...columnSorters.text({ display, property, unique: UNIQUE }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  // RECORD COLUMNS
  totalArrivedAmount: props => {
    const property = 'totalArrivedAmount';
    const display = numericDisplay(DEFAULT_PRECISION);
    return createColumnProps({
      Header: __('total_arrived_amount'),
      id: property,
      accessor: record => record.status === OrderRecordStatusEnum.ARRIVED ? getTotalRecordSkuNormalizedAmount(record, record.sku) : null,
      exporter: ({ cell: { value }}) => display(value),
      Cell: ({ cell: { value }}) => display(value),
      ...columnAggregators.sum({ property, display }),
      Aggregated: ({ cell: { value }, row: { values, leafRows }}) => {
        let comparator = numberComparator(value, values['totalAmount']);
        let [row] = leafRows;
        let { recordsetStatus } = row.original;
        let amount = recordsetStatus == OrderStatusEnum.FINISHED && !value ? 0 : value;
        return (
          <span>
            {display(amount)}
            {recordsetStatus == OrderStatusEnum.FINISHED &&
              <Icon
                variant={totalArrivedAmountIconProps[comparator].variant}
                icon={totalArrivedAmountIconProps[comparator].icon}
              />
            }
          </span>);
      },
      ...columnSorters.number({ property }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  unit: ({ property = 'unit', unitProperty = UnitPropertyEnum.SHORT_NAME, ...props } = {}) => {
    const display = unit => unit ? __unit(unit.id, unitProperty, 1) : null;
    return createColumnProps({
      Header: __('general_unit'),
      id: property,
      accessor: row => UnitEnum.get(get(row, property, null)),
      Cell: ({ cell: { value: unit }}) => display(unit),
      exporter: ({ cell: { value: unit }}) => display(unit),
      ...columnAggregators.listUniq({ unique: UNIQUE, display, info: null }),
      ...columnSorters.text({ unique: UNIQUE, property, display }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  barName: ({ property = 'barName', ...props } = {}) => {
    const display = bar => bar;
    return createColumnProps({
      Header: __('general_bar'),
      id: property,
      accessor: 'bar.name',
      ...columnAggregators.listUniq({ unique: UNIQUE, display, info: null }),
      ...columnSorters.text({ unique: UNIQUE, property, display }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  measurement: ({ precision = DEFAULT_PRECISION, ...props } = {}) => {
    const property = 'measurement';
    const display = measurement => measurement.localeDisplay({ precision });
    const dummy = new Measurement({ amount: null, unit: null });
    return createColumnProps({
      Header: __('general_units'), //donno
      id: property,
      exporter: ({ cell /*, row*/ }) => display(cell.value),
      accessor: record => {
        // console.log('record', getRecordsUnitNormalizedMeasurement([record], record.sku));
        return record._temp ? dummy : getRecordsUnitNormalizedMeasurement([record], record.sku);
      },
      Cell: ({ cell: { value: measurement }}) => display(measurement),
      ...columnAggregators.dummy({ dummy, display }),
      ...columnSorters.measurement({ property }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  totalMeasurement: ({ property = 'totalMeasurement', precision = DEFAULT_PRECISION, ...props } = {}) => {
    const display = measurement => measurement.localeDisplay({ precision });
    return createColumnProps({
      Header: __('general_total_measurement'), //donno
      id: property,
      exporter: ({ cell /*, row*/ }) => display(cell.value),
      // Aggregated: ({ row }) => {
      //   const records = row.values[property];
      //   return records.length ? getTotalRecordsUnitNormalizedMeasurement(records, records[0].sku).localeDisplay({ precision: DEFAULT_PRECISION }) : null;
      //   // console.log('rows', getRows(row));
      //   // const uniqSkuUnitIds = uniq(map(getNestedValue(row.subRows, nestedPath), (row) => getSkuUnit(getNestedValue(row.original, nestedPath).sku).id));
      //   // return uniqSkuUnitIds.length === 1 ? value + ' ' + __unit(uniqSkuUnitIds[0], 'name_short') : '(multiple units)';
      // },
      accessor: record => getTotalRecordsUnitNormalizedMeasurement([record], record.sku),
      Cell: ({ cell: { value: measurement }}) => display(measurement),
      // sortType: (row1, row2, desc) =>
      //   recordTotalAmountComparator(getNestedValue(row1.original, nestedPath), getNestedValue(row2.original, nestedPath), desc)
      // filterMethod: (filter, row) => { console.log('filter', filter); console.log('row', row); return true; }
      ...columnAggregators.measurement({ property, display }),
      ...columnSorters.measurement({ property }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  totalMeasurementAmount: ({ property = 'totalMeasurementAmount', precision = DEFAULT_PRECISION, ...props } = {}) => {
    const display = measurement => measurement.amount;
    return createColumnProps({
      Header: __('general_total_measurement_amount'), //donno
      id: property,
      exporter: ({ cell /*, row*/ }) => display(cell.value),
      accessor: record => getTotalRecordsUnitNormalizedMeasurement([record], record.sku),
      Cell: ({ cell: { value: measurement }}) => display(measurement),
      ...columnAggregators.measurement({ property, display }),
      ...columnSorters.none(), // ...columnSorters.measurement({ property }),
      ...columnFilters.number({ property, display: measurement => '' + measurement.amount })
    }, props);
  },

  totalMeasurementUnit: ({ property = 'totalMeasurementUnit', unitProperty = UnitPropertyEnum.SHORT_NAME, ...props } = {}) => {
    const display = measurement => measurement.unit ? __unit(measurement.unit.id, unitProperty, 1) : null;
    return createColumnProps({
      Header: __('general_total_measurement_unit'), //donno
      id: property,
      exporter: ({ cell /*, row*/ }) => display(cell.value),
      accessor: record => getTotalRecordsUnitNormalizedMeasurement([record], record.sku),
      Cell: ({ cell: { value: measurement }}) => display(measurement),
      ...columnAggregators.measurement({ property, display: measurement => display(measurement) }),
      ...columnSorters.none(), //...columnSorters.measurement({ property }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  quantity: ({ recordType, accessor, property = 'quantity', ...props } = {}) => {
    const _accessor = accessor || (record => record ? record[property] : null);
    return createColumnProps({
      id: property,
      Header: __('general_quantity'),
      accessor: _accessor,
      formElement: 'input',
      formElementProps: { name: property, type: 'number' },
      rowScheme: ({ original }) => `${recordType}.${original.sku.stocktakeMode}.${original.mode}.update`,
      rowValues: ({ original: { recordsetId, mode, status, type, sku }}) => ({ recordsetId, mode, status, type, localSku: sku }),
      ...columnAggregators.none(),
      ...columnSorters.number({ property/*, accessor: _accessor*/ }),
      ...columnFilters.number({ property })
    }, props);
  },

  //FIXME: merge with columnConfig.amountWithUnit (and measurement?)
  //TODO:: care for aggregated values/sorting
  productWeightWithUnit: props => {
    const property = 'productWeight';
    return createWeightColumnProps({
      Header: __('general_product_weight'),
      id: property,
      property,
      recordMeasure: RecordMeasureEnum.PRODUCT_WEIGHT
    }, props);
  },

  //FIXME: merge with columnConfig.amountWithUnit (and measurement?)
  //TODO:: care for aggregated values/sorting
  packageClosureWeightWithUnit: props => {
    const property = 'packageClosureWithUnit';
    return createWeightColumnProps({
      Header: __('general_bottle_cap_weight_plus_unit'),
      id: property,
      property,
      recordMeasure: RecordMeasureEnum.PACKAGE_CLOSURE_WEIGHT
    }, props);
  },

  orderRecordsTotalArrivedValue: ({ precision = DEFAULT_PRECISION, recordValueProperty, skuValueProperty, ...props } = {}) => {
    const display = numericDisplay(precision);
    const property = `${recordValueProperty}.${skuValueProperty}`;
    return createColumnProps({
      Header: __('general_total_purchase_price'),
      id: property,
      exporter: ({ cell }) => display(cell.value),
      accessor: record => record.status === OrderRecordStatusEnum.ARRIVED ? getTotalRecordSkuPortionPrice(record, record.sku, recordValueProperty, skuValueProperty) : null,
      Cell: ({ row: { original: record }}) =>
        record && record.status === OrderRecordStatusEnum.ARRIVED ? (
          <RecordsValue
            records={[record]}
            sku={record.sku}
            recordValueProperty={recordValueProperty}
            skuValueProperty={skuValueProperty}
          />) : null,
      aggregate: (values/*, rows*/) => sum(values),
      Aggregated: ({ row }) => {
        let records = row.isGrouped ? row.leafRows.map(row => row.original) : [row.original];
        return records.length ? (
          <RecordsValue
            records={records.filter(record => record.status === OrderRecordStatusEnum.ARRIVED)}
            sku={records[0].sku}
            recordValueProperty={recordValueProperty}
            skuValueProperty={skuValueProperty}
          />
        ) : null;
      },
      ...columnSorters.number({ property }),
      ...columnFilters.text({ property })
    }, props);
  },

  recordsValue: ({ precision = DEFAULT_PRECISION, recordValueProperty, skuValueProperty, path, ...props } = {}) => {
    const property = `${recordValueProperty}.${skuValueProperty}`;
    const getRecordPath = row => path ? get(row, path) : row;
    const display = numericDisplay(precision);
    return createColumnProps({
      Header: __('general_records_value'),
      id: property,
      exporter: ({ cell /*, row*/ }) => display(cell.value),
      accessor: row => getTotalRecordSkuPortionPrice(getRecordPath(row), getRecordPath(row).sku, recordValueProperty, skuValueProperty),
      Cell: ({ row: { original }}) =>
        getRecordPath(original) ? (
          <RecordsValue
            records={[getRecordPath(original)]}
            sku={getRecordPath(original).sku}
            recordValueProperty={recordValueProperty}
            skuValueProperty={skuValueProperty}
          />) : null,
      ...columnAggregators.recordValue({ recordValueProperty, skuValueProperty, display, path }),
      ...columnSorters.number({ property }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  recordsSaleValue: ({ precision = DEFAULT_PRECISION, recordValueProperty, ...props } = {}) => {
    const property = `${recordValueProperty}`;
    const display = numericDisplay(precision);
    const accessor = record => record.quantity * record[recordValueProperty];
    return createColumnProps({
      Header: __('general_records_value'),
      id: property,
      exporter: ({ cell /*, row*/ }) => display(cell.value),
      accessor: record => accessor(record), //getTotalRecordSkuPortionPrice(record, record.sku, recordValueProperty, skuValueProperty),
      Cell: ({ row: { original: record }}) => {
        return record ? (
          <RecordsSaleValue
            records={[record]}
            accessor={accessor}
          />) : null;
      },
      ...columnAggregators.recordSaleValue({ recordValueProperty, accessor, display }),
      ...columnSorters.number({ property }),
      ...columnFilters.text({ property })
    }, props);
  },

  //FIXME: merge with columnConfig.amountWithUnit (and measurement?)
  //TODO:: care for aggregated values/sorting
  scaleWeightWithUnit: props => {
    const property = 'scaleWeightWithUnit';
    return createWeightColumnProps({
      Header: __('general_scale_weight'),
      id: property,
      property,
      recordMeasure: RecordMeasureEnum.SCALE_WEIGHT
    }, props);
  },

  skuBarcode: ({ property = 'sku.barcode', ...props } = {}) => {
    return createColumnProps({
      Header: __('general_barcode'),
      id: property,
      accessor: property,
      formElement: 'barcode',
      formElementProps: {
        formatName: 'barcodeFormat'
      },
      ...columnAggregators.hasUniq(),
      ...columnSorters.text({ property }),
      ...columnFilters.text({ property })
    }, props);
  },

  category: ({ property = 'category', ...props } = {}) => {
    const display = category => __category(category);
    return createColumnProps({
      Header: __('general_category'),
      id: property,
      exporter: ({ cell /*, row*/ }) => display(cell.value),
      Cell: ({ cell: { value: category }}) => display(category),
      ...columnAggregators.listUniq({ unique: UNIQUE, property, display, info: null }),
      ...columnSorters.text({ unique: UNIQUE, property, display }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  skuImage: ({ id = 'sku.image', accessor, ...props } = {}) => {
    return createColumnProps({
      id,
      Header: __('general_image'),
      Cell: ({ cell }) => {
        return (
          <div className="RecordRow-td RecordRow-td-image">
            <SkuImage sku={accessor(cell.row)} />
          </div>
        );
      },
      // ...columnAggregators.none(),
      ...columnAggregators.image({ accessor }),
      ...columnSorters.none(),
      ...columnFilters.none(),
      disableExport: true
    }, props);
  },

  id: ({ property = 'id', ...props }) => {
    return createColumnProps({
      Header: __('general_id'),
      id: property,
      accessor: property,
      disableGroupBy: false,
      ...columnAggregators.hasUniq(),
      ...columnSorters.number({ property }),
      ...columnFilters.number({ property })
    }, props);
  },

  skuName: ({ property = 'sku.name', ...props } = {}) => {
    return createColumnProps({
      Header: __('general_name'),
      id: property,
      accessor: property,
      ...columnAggregators.hasUniq(),
      ...columnSorters.text({ property }),
      ...columnFilters.text({ property })
    }, props);
  },

  skuOfficial: ({ property = 'sku.official', unique = UNIQUE, ...props } = {}) => {
    const display = value => typeof(value) !== 'undefined' ? __yesNo(value) : null;
    const accessor = record => record.sku.official;
    return createColumnProps({
      id: property,
      accessor,
      ...columnAggregators.listUniq({
        property,
        accessor,
        display,
        info: null
      }),
      ...columnSorters.text({ property, display, accessor, unique }),
      ...columnFilters.text({ property, display, accessor, unique })
    }, props);
  },

  skuSize: ({ precision = DEFAULT_PRECISION, ...props } = {}) => {
    const property = 'skuSize';
    const display = measurement => measurement.localeDisplay({ precision });
    return createColumnProps({
      Header: __('general_size'),
      id: property,
      accessor: row => getSkuSizeMeasurement(row.sku),
      exporter: ({ cell/*, row*/ }) => display(cell.value),
      Cell: ({ cell: { value: measurement }}) => display(measurement),
      ...columnAggregators.listUniq({ display, info: null }),
      ...columnSorters.none(), // FIXME: ...columnSorters.text({ property, display }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  recordsetGroupStatus: ({ property, accessor, translator, ...props } = {}) => {
    const display = status => translator(status);
    return createColumnProps({
      Header: __('general_recordset_group_status'),
      id: property,
      accessor,
      Cell: ({ cell: { value: status }}) => display(status),
      ...columnAggregators.none(),
      ...columnSorters.text({ property, display }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  recordMode: (props = {}) => {
    const property = 'mode';
    const display = mode => __recordMode(mode);
    return createColumnProps({
      Header: __('general_stocktake_mode'),
      id: property,
      accessor: row => (row.mode),
      exporter: ({ cell: { value }}) => display(value),
      Cell: ({ cell: { value: mode }}) => display(mode),
      ...columnAggregators.listUniq({ unique: UNIQUE, display, info: null }),
      ...columnSorters.text({ unique: UNIQUE, property, display }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  skuStocktakeMode: props => {
    const property = 'skuStocktakeMode';
    const display = stocktakeMode => __skuStocktakeMode(stocktakeMode);
    return createColumnProps({
      Header: __('general_stocktake_mode'),
      id: property,
      accessor: 'stocktakeMode',
      exporter: ({ cell /*, row*/ }) => display(cell.value),
      Cell: ({ cell: { value: stocktakeMode }}) => display(stocktakeMode),
      ...columnAggregators.listUniq({ unique: UNIQUE, display, info: null }),
      ...columnSorters.text({ property, display }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  status: props => {
    const property = 'status';
    const display = status => __recordStatus(status);
    return createColumnProps({
      Header: __('general_status'),
      id: 'status',
      accessor: 'status',
      Cell: ({ row: { values }}) => display(values[property]),
      exporter: ({ cell }) => display(cell.value),
      ...columnAggregators.listUniq({ unique: UNIQUE, display, info: null }),
      ...columnSorters.text({ unique: UNIQUE, property, display }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  storageStandardStatus: ({ property, ...props } = {}) => {
    const display = status => __storageStandardStatus(status);
    return createColumnProps({
      Header: __('general_status'),
      id: property,
      accessor: property,
      Cell: ({ cell: { value }}) => {
        return value == StorageStandardStatusEnum.ACTIVE ? (
          <span className="text-success">{display(value)}</span>
        ) : (
          <span className="text-muted">{display(value)}</span>
        );
      },
      ...columnAggregators.none(),
      ...columnSorters.text({ property, display }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  amount: ({ precision = DEFAULT_PRECISION, ...props } = {}) => {
    const property = 'amount';
    const display = numericDisplay(precision);
    return createColumnProps({
      Header: __('general_amount'),
      id: property,
      exporter: ({ cell /*, row*/ }) => display(cell.value),
      // Aggregated: ({ cell: { value }}) => value ? value.toFixed(DEFAULT_PRECISION) : null,
      accessor: record => getRecordSkuNormalizedAmount(record, record.sku),
      Cell: ({ cell: { value }}) => display(value),
      disableGroupBy: true,
      ...columnAggregators.none(),
      ...columnSorters.number({ property }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  totalAmount: ({ precision = DEFAULT_PRECISION, ...props } = {}) => {
    const property = 'totalAmount';
    const display = numericDisplay(precision);
    return createColumnProps({
      Header: __('general_total_amount'),
      id: property,
      exporter: ({ cell /*, row*/ }) => display(cell.value),
      accessor: record => getTotalRecordSkuNormalizedAmount(record, record.sku),
      Cell: ({ cell: { value }}) => display(value),
      ...columnAggregators.sum({ property, display }),
      ...columnSorters.number({ property }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  //FIXME: merge with columnConfig.amountWithUnit (and measurement?)
  //TODO:: care for aggregated values/sorting
  volumeWithUnit: ({ precision = DEFAULT_PRECISION, ...props } = {}) => {
    const display = measurement => measurement.localeDisplay({ precision });
    const property = 'volumeWithUnit';
    const dummy = new Measurement();
    return createColumnProps({
      Header: __('general_volume_plus_unit'),
      id: property,
      exporter: ({ cell /*, row*/ }) => display(cell.value),
      accessor: record => getRecordMeasureMeasurement(record, record.sku, RecordMeasureEnum.VOLUME),
      Cell: ({ cell: { value: measurement }}) => display(measurement),
      ...columnAggregators.dummy({ dummy, display }),
      ...columnSorters.measurement({ property }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  //FIXME: merge with columnConfig.measurement?
  amountWithUnit: ({ precision = DEFAULT_PRECISION, amountProperty, unitIdProperty, property = `${amountProperty}.${unitIdProperty}`, ...props } = {}) => {
    const display = measurement => measurement.localeDisplay({ precision });
    return createColumnProps({
      id: property,
      accessor: object => new Measurement({ amount: get(object, amountProperty, null), unit: UnitEnum.get(get(object, unitIdProperty, null)) }),
      Cell: ({ cell: { value: measurement }}) => display(measurement),
      exporter: ({ cell: { value: measurement }}) => display(measurement),
      ...columnAggregators.listUniq({ display, unique: ({ values }) => display(values[0]), info: null }),
      ...columnSorters.measurement({ property }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  reportAmount: ({ property = 'reportAmount', precision = DEFAULT_PRECISION, ...props } = {}) => {
    const display = numericDisplay(precision);
    return createColumnProps({
      Header: __('general_total_report_amount'),
      id: property,
      accessor: record => getTotalRecordReportAmount(record, record.sku),
      Cell: ({ cell: { value: amount }}) => display(amount),
      ...columnAggregators.sum({ property, display }),
      ...columnSorters.number({ property }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  // reportUnit: ({ property = 'reportUnit', unitProperty = UnitPropertyEnum.SHORT_NAME, ...props } = {}) => {
  //   const display = unit => unit ? __unit(unit.id, unitProperty, 1) : null;
  //   return createColumnProps({
  //     Header: __('general_report_unit'),
  //     id: property,
  //     accessor: row => UnitEnum.get(get(row, property, null)),
  //     Cell: ({ cell: { value: unit }}) => display(unit),
  //     exporter: ({ cell: { value: unit }}) => display(unit),
  //     ...columnAggregators.listUniq({ unique: UNIQUE, display, info: null }),
  //     ...columnSorters.text({ unique: UNIQUE, property, display }),
  //     ...columnFilters.text({ property, display })
  //   }, props);
  // },

  reportMeasurement: ({ property = 'reportMeasurement' } = {}) => {
    const display = measurement => `${measurement.amount} ${__unit(measurement.unit.id)}`;
    return createColumnProps({
      Header: __('general_total_report_unit'),
      id: property,
      accessor: record => {
        let { sku } = record;
        return getReportSkuMeasurement(sku);
      },
      Cell: ({ cell: { value: measurement }}) => display(measurement),
      exporter: ({ cell /*, row*/ }) => display(cell.value),
      ...columnAggregators.listUniq({ property, display, info: null }),
      ...columnSorters.text({ property, display, unique: UNIQUE }),
      ...columnFilters.text({ property, display })
    });
  },

  customReportAmount: ({ property = 'customReportAmount', unit, precision = DEFAULT_PRECISION, ...props } = {}) => {
    const display = numericDisplay(precision);
    return createColumnProps({
      Header: __('general_total_report_amount', { details: `(${__unit(unit.id, UnitPropertyEnum.NAME)})` }),
      id: property,
      accessor: record => {
        const { sku } = record;
        if ([SkuStocktakeModeEnum.VOLUME, SkuStocktakeModeEnum.QUANTITY, SkuStocktakeModeEnum.QUANTITY_VOLUME].includes(sku.stocktakeMode) && sku.volume && sku.volumeUnitId)
          return getTotalRecordUnitNormalizedAmount(record, sku, unit.id) || 0;
        return 0;
      },
      Cell: ({ cell: { value: amount }}) => display(amount),
      ...columnAggregators.sum({ property, display }),
      ...columnSorters.number({ property }),
      ...columnFilters.text({ property, display })
    }, props);
  },

  totalValue: ({ property, recordsetType, isGroup, precision = DEFAULT_PRECISION, autoclick = false, recordStatus, ...props }) => {
    return createColumnProps({
      id: property,
      accessor: recordset => (isGroup ? { recordsetGroupId: recordset.id } : { recordsetId: recordset.id, recordsetGroupId: recordset.recordsetGroupId }),
      Cell: ({ cell: { value }}) => {
        return (
          <RecordsetStatistics
            recordsetGroupId={value.recordsetGroupId}
            recordsetId={value.recordsetId}
            recordsetType={recordsetType}
            property={property}
            precision={precision}
            autoclick={autoclick}
            recordStatus={recordStatus}
            __object={value} //FIXME: export workaround
          />
        );
      },
      exporter: ({ cell: { value }}) => value[property] || null
    }, props);
  },

  recordSupplier: ({ property = 'recordSupplier', orders, ...props }) => {
    return createColumnProps({
      Header: __('general_supplier'),
      id: property,
      accessor: record => orders.find(({ id }) => id === record.recordsetId)?.supplier?.name || '',
      ...columnAggregators.listUniq({ info: null }),
      ...columnSorters.text({ property }),
      ...columnFilters.text({ property })
    }, props);
  },

  skuSuppliers: ({ property = 'skuSuppliers', suppliers, skuSuppliers, ...props }) => {
    return createColumnProps({
      Header: __('general_suppliers'),
      id: property,
      accessor: record => skuSuppliers?.filter(({ skuId }) => skuId === record.skuId)?.map(({ supplierId }) => suppliers.find(({ id }) => id === supplierId)?.name)?.join(', ') || '',
      ...columnAggregators.hasUniq(),
      ...columnSorters.text({ property }),
      ...columnFilters.text({ property })
    }, props);
  },

  reportData: ({ property, recordsetType, precision = DEFAULT_PRECISION, path, ...props }) => {
    const display = number => getReportableAmount(number, precision, null);
    return createColumnProps({
      id: property,
      Cell: ({ cell: { value }}) => display(value),
      accessor: entry => get(entry, `[${recordsetType}.${path}]`, 0),
      exporter: ({ cell: { value }}) => round(value, precision)
    }, props);
  },

  reportableAmount: ({ property, precision = DEFAULT_PRECISION, path, ...props }) => {
    return createColumnProps({
      id: property,
      accessor: entry => getReportableAmount(get(entry, path), precision, null),
      exporter: ({ cell: { value }}) => round(value, precision)
    }, props);
  },

  skuDetailsDate: ({ property, accessor, dateFormat = 'L LT', language = getLanguage(), skuDetailsProperty, ...props }) => {
    const display = details => details && details[skuDetailsProperty] ? formatDate(details[skuDetailsProperty], language, dateFormat) : null;
    return createColumnProps({
      id: property,
      accessor,
      display,
      exporter: ({ cell: { value }}) => display(value),
      Cell: ({ cell: { value }}) => display(value),
      ...columnAggregators.listUniq({ property, display, info: null }),
      ...columnSorters.none(),
      ...columnFilters.none()
      //...columnSorters.date({ property }),
      //...columnFilters.text({ property, display })
    }, props);
  },

  skuDetailsPrice: ({ property, accessor, skuDetailsProperty, precision = DEFAULT_PRECISION, ...props }) => {
    const display = details => details && details[skuDetailsProperty] != null ? numericDisplay(precision)(details[skuDetailsProperty]) : null;
    return createColumnProps({
      id: property,
      accessor,
      display,
      exporter: ({ cell: { value }}) => display(value),
      Cell: ({ cell: { value }}) => display(value),
      ...columnAggregators.listUniq({ property, display, info: null }),
      ...columnSorters.number({ property, display }),
      ...columnFilters.number({ property, display })
    }, props);
  },

  //FIXME: row-dependant Badge key
  tags: ({ property, accessor, ...props }) => {
    const display = (tags = []) => (
      <Fragment>
        {
          tags.filter(({ id }) => id).map(tag => (
            <Badge
              key={`tag-${tag.id}`}
              pill
              tag="div"
              className="d-inline-block mr-2"
              style={{ backgroundColor: tag.rgbColor || 'gray', fontSize: '11px' }}
            >
              {tag.name}
            </Badge>)
          )
        }
      </Fragment>
    );
    return createColumnProps({
      id: property,
      accessor,
      display,
      exporter: ({ cell: { value: tags }}) => tags.map(tag => tag.name).join(', '),
      Cell: ({ cell: { value: tags }}) => display(tags),
      ...columnAggregators.listUniq({ property, display, info: null, unique: ({ values, display }) => display(values[0]) }),
      //FIXME: improve ...columAggregators.listUniq({ ... })
      aggregate: (values/*, rows*/) => {
        return values;
      },
      Aggregated: ({ cell: { value }}) => {
        let values = [];
        //FIXME: aggregate subRows
        if (Array.isArray(value))
          values = value;
        else
          values = [value];
        const uniqueValues = display(values[0]);
        return <DefaultAggregated value={uniqueValues} info={null} />;
      },
      aggregatedExporter: ({ cell: { value }}) => {
        let values = [];
        //FIXME: aggregate subRows
        if (Array.isArray(value))
          values = value;
        else
          values = [value];
        const uniqueValues = values[0].map(tag => tag.name).join(', ');
        return uniqueValues;
      },
      ...columnSorters.none(),
      ...columnFilters.text({ property, display: tags => tags.map(tag => tag.name).join(', ') })
    }, props);
  },

  nestedEnumerableProperty: ({ nestedProperty, propertyPrefix, enumerationAccessor, nestedPropertyAccessor, join = arr => arr.join(', '), displayValue = identity, displayValues, ...props }) => {
    const display = values => displayValues ? displayValues(values) : join(values.map(value => displayValue(nestedPropertyAccessor(value))));
    const property = `${propertyPrefix}.${nestedProperty}`;
    return createColumnProps({
      id: property,
      expandBy: false,
      expand: true,
      accessor: entry => enumerationAccessor(entry),
      Cell: ({ cell: { value: values }}) => display(values),
      // aggregate: (values/*, row*/) => values,
      // Aggregated: ({ cell: { value: values }}) => display(values),
      exporter: ({ cell/*, row*/, i }) => displayValue(nestedPropertyAccessor(cell.value[i])),
      ...columnSorters.none(),
      ...columnFilters.text({ property, display })
    }, props);
  },

  draggable: ({ type, preview = () => null, ...props }) => {
    return createColumnProps({
      id: 'draggable',
      label: '☩', //FIXME: move to locales
      Header: () => <Icon icon="arrows" type="solid" />,
      accessor: row => row.id,
      Cell: ({ row, cell: { value }}) => <TableDraggableCell type={type} item={{ ids: [value] }} preview={preview(row.original)} />,
      aggregate: (values/*, row*/) => values,
      Aggregated: ({ row, cell: { value: values }}) => <TableDraggableCell type={type} item={{ ids: values }} preview={preview(row.isGrouped ? row.leafRows.map(row => row.original)[0] : row.original)} />,
      disableFilters: true,
      disableResizing: true,
      disableExport: true,
      disableSortBy: true,
      minWidth: 40,
      width: 40,
      maxWidth: 40
    }, props);
  }

};