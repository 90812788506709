"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _validatorConstants = require("../validator/validatorConstants.js");

var payloadScheme = {
  exclude: _validatorConstants.BOOLEAN.optional(),
  contextId: _validatorConstants.INTEGER.optional([1]),
  skuId: (0, _validatorConstants.WHEN)('exclude', {
    //FIXME: both same type and required
    is: true,
    then: _validatorConstants.NULL.optional(),
    //FIXME: null required
    otherwise: _validatorConstants.INTEGER.required([1])
  }),
  amount: (0, _validatorConstants.WHEN)('exclude', {
    //FIXME: both same type and required
    is: true,
    then: _validatorConstants.NULL.optional(),
    //FIXME: null required
    otherwise: _validatorConstants.INTEGER.required([1])
  }),
  amountUnitId: (0, _validatorConstants.WHEN)('exclude', {
    //FIXME: both same type and required
    is: true,
    then: _validatorConstants.NULL.optional(),
    //FIXME: null required
    otherwise: _validatorConstants.INTEGER.required([1])
  })
};
var _default = {
  parse: {
    //FIXME: dependant import
    columnMap: _validatorConstants.OBJECT.required({
      id: _validatorConstants.STRING.optional([1]),
      name: _validatorConstants.STRING.optional([1]),
      quantity: _validatorConstants.STRING.optional([1]),
      unit_price: _validatorConstants.STRING.optional([1]),
      total_price: _validatorConstants.STRING.optional([1])
    }),
    //FIXME: delimiter/newline optional for CSV files
    delimiter: _validatorConstants.OBJECT.optional(),
    //toArray(find(CsvParseConstant, { name: 'delimiter' }).props)),
    newline: _validatorConstants.OBJECT.optional(),
    //toArray(find(CsvParseConstant, { name: 'newline' }).props)),
    row: _validatorConstants.INTEGER.required([0])
  },
  map: {
    eventDate: _validatorConstants.DATE.required([1]),
    payload: _validatorConstants.ARRAY.required(_validatorConstants.OBJECT.required(payloadScheme))
  },
  invoice: {
    id: _validatorConstants.STRING.optional([1]),
    name: _validatorConstants.STRING.optional([1]),
    quantity: _validatorConstants.STRING.optional([1]),
    price: _validatorConstants.STRING.optional([1]),
    unit: _validatorConstants.STRING.optional([1])
  }
};
exports["default"] = _default;